/* Side panel styles */
.side-panel {
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background-color: #1c1c1e;
  padding: 20px;
  box-sizing: border-box;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 2; /* Ensure it's above other elements */
}

.side-panel.open {
  left: 0;
}

.close-panel {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #007aff;
  cursor: pointer;
}

.side-panel h3 {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #007aff;
}

.side-panel p {
  line-height: 1.6;
  color: #fff;
}

.side-panel a {
  color: #f08080;
}
