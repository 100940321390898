.info-popup {
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #1c1c1e;
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  font-family: "Roboto", sans-serif;
}

.info-popup.open {
  right: 0;
}

.info-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 600px;
}

.profile-section {
  position: relative;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: rgba(200, 200, 200, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.close-icon {
  color: #fff;
  font-size: 28px;
  line-height: 1;
}

.profile-name {
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: 3em;
  animation: fallAndBounce 1s ease-out;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
  z-index: 1;
}

.lucide {
  color: #f08080;
}

.action-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  animation: fallAndBounce 1s ease-out;
}

.action-button:hover:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.3);
}

.action-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.contact-info {
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.info-item {
  background-color: #2c2c2e;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: fallAndBounce 1s ease-out;
}

.info-item h3 {
  color: #8e8e93;
  margin-bottom: 5px;
}

.info-item p {
  color: #fff;
}

a {
  color: #f08080;
}

.pulsing-highlight {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulsing-highlight::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
  border: 2px solid #f08080;
  animation: pulse 1.5s infinite;
}

.avatar-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;  /* Ensure the avatar is above the pulse effect */
}

.avatar-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fallAndBounce {
  0% {
    transform: translateY(-100vh); /* Start from off-screen at the top */
    opacity: 0;
  }
  60% {
    transform: translateY(0); /* Move to final position */
    opacity: 1;
  }
  70% {
    transform: translateY(-30px); /* Bounce up slightly */
  }
  80% {
    transform: translateY(0); /* Settle back down */
  }
  90% {
    transform: translateY(-15px); /* Smaller bounce */
  }
  100% {
    transform: translateY(0); /* Final settle */
  }
}