/* ChatWindow.css */

.chat-window {
  display: flex;
  height: 100lvh;
  min-height: 100lvh;
  max-width: 600px;
  margin: auto;
  border: 1px solid #343541;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  background-color: #000000;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.chat-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.chat-content.shifted {
  transform: translateX(50%);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #1c1c1e;
  border-bottom: 1px solid #38383a;
}

.header-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #007aff;
}

.header-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.header-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure messages stack from the top */
  color: #fff;
}

.messages .message:first-child {
  margin-top: auto; /* Push the first message to the bottom */
}

.input-area {
  display: flex;
  border-top: 1px solid #343541;
  padding: 10px;
  align-items: center; /* Center items vertically */
  margin-top: auto; /* Ensure the input area is always at the bottom */
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: 1px solid #343541;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #1c1c1e;
  color: #fff;
  font-size: 16px; /* Ensure the font size is at least 16px to prevent zooming */
}

.send-button {
  background-color: #007aff; /* iMessage blue color */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: background-color 0.3s ease;
}

.send-button svg {
  width: 20px;
  height: 20px;
  color: white;
}

.send-button:hover {
  background-color: #005bb5;
}

.send-button:active {
  background-color: #00408d;
}

/* Error message styling */
.error-message {
  position: absolute;
  bottom: 60px; /* Adjust this value based on the height of the input area */
  left: 0;
  right: 0;
  color: red;
  padding: 10px;
  text-align: center;
  z-index: 1; /* Ensure it's above the input area */
}

.example-messages {
  display: flex;
  gap: 8px;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 10px;
}

.example-message-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  animation: floatIn 0.5s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

/* Add animation delay for each button */
.example-message-button:nth-child(1) {
  animation-delay: 0.2s;
}

.example-message-button:nth-child(2) {
  animation-delay: 0.4s;
}

.example-message-button:nth-child(3) {
  animation-delay: 0.6s;
}

.example-message-button:nth-child(4) {
  animation-delay: 0.8s;
}

@keyframes floatIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}