/* ChatMessage.css */

.message {
  display: flex;
  margin: 10px 0;
  align-items: center; /* Align items vertically centered */
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
  color: white;
}

.message-content {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #212121;
  font-size: 16px;
  display: flex;
  align-items: center; /* Ensure content and icon are vertically centered */
  position: relative;
}

.message.user .message-content {
  background-color: #00408d;
  color: white;
}

.typing-indicator {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #212121; /* Match the background color of message-content */
  color: #fff; /* Match the text color */
}

.typing-indicator .dot {
  width: 8px;
  height: 8px;
  background-color: #999;
  border-radius: 50%;
  margin: 0 2px;
  animation: blink 1.4s infinite both;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Styles for the Speak button with speaker icon */
.speak-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Vertically center the icon */
}

.speak-button svg {
  width: 24px;
  height: 24px;
  fill: #007bff; /* Color of the speaker icon */
}

.speak-button:hover svg {
  fill: #0056b3; /* Change icon color on hover */
}

/* Styles for the Stop button with red icon */
.stop-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Vertically center the icon */
}

.stop-button svg {
  width: 24px;
  height: 24px;
  fill: red; /* Red color for the stop button */
}

.stop-button:hover svg {
  fill: darkred; /* Change icon color on hover */
}
